<template>
    <div>
        <!-- 头部 -->
        <div class="mian_top" >
            <el-form :model="cond" :inline="true">
                <el-form-item  class="queryCondition" >
                    <el-select v-model="cond.bill_category_id" placeholder="请选择内容" style="width:200px;"  clearable>
                        <el-option
                        v-for="item in options"
                        :key="item.bill_category_id"
                        :label="item.name"
                        :value="item.bill_category_id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="" class="queryCondition">
                    <el-input class="queryCondition_input" placeholder="请输入" v-model="cond.name" clearable></el-input>
                </el-form-item>
              
            </el-form>

            <div class="mian_top_right">
                <el-button type="primary" class="senior_screening_query" @click="getBillTypeList">查询</el-button>
                <el-button class="senior_screening_reset" @click="reset()">重置</el-button>
            </div>
        </div>

        <!-- 表格 -->
        <div class="mian_tab">
        <div class="headerBox">
            <div class="headerBox_title">
                <span></span>
            </div>

            <div class="operate">
                <el-button type="primary" icon="el-icon-plus" size="mini" @click="addData">新增</el-button>
                <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteBillCategoryList" >删除</el-button>
                <el-button size="mini" @click="setOnoff(20)">禁用</el-button>
                <el-button size="mini" @click="setOnoff(10)">启用</el-button>
                <el-upload
                    :action="baseUrl + '/store/goods.BillTypeConfig/import'"
                    name="datafile"
                    :headers="{
                        authorization: token,
                    }"
                    :show-file-list="false"
                    :on-success="handleImportSuccess"
                    style="display:inline-block;margin:0 10px;">
                    <el-button size="mini">导入</el-button>
                </el-upload>
                <el-button size="mini" @click="exportList">导出</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="reflush">刷新</el-button>
            </div>
        </div>

        <!-- 表格 -->
            <el-table
                ref="multipleTable"
                @row-click="clickRow"
                @row-dblclick="handleDalclick"
                @select="handleSelectionChangeList"
                @select-all="tableGetAll"
                :data="tableData"
                border
                v-loading="loading"
                style="width: 100%" height="650">

                <el-table-column
                    type="selection"
                    width="55">
                </el-table-column>

                <el-table-column
                    type="index"
                    label="序号"
                    width="50" align="center">
                </el-table-column>

                <el-table-column
                    prop="bill_category_name"
                    label="所属单据"
                    width="180" align="center">
                </el-table-column>

                <el-table-column
                    prop="name"
                    label="单据类型"
                    width="180" align="center">
                    <template slot-scope="scope">
                        <el-link type="primary" @click="editBox(scope.row)">{{scope.row.name}}</el-link>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="order_no"
                    label="排序" align="center">
                </el-table-column>

                <el-table-column
                    prop="status"
                    label="状态" align="center">
                    <template slot-scope="scope">
                        <el-switch @change="switchChange(scope.row)"
                            style="display: block"
                            v-model="scope.row.status"
                            :active-value="10"
                            :inactive-value="20">
                        </el-switch>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="store_user_name"
                    label="添加人" align="center">
                </el-table-column>

                <el-table-column
                    prop="create_time"
                    label="添加时间" align="center">
                </el-table-column>

                <el-table-column
                    prop="store_user_update_name"
                    label="修改人" align="center">
                </el-table-column>

                 <el-table-column
                    prop="update_time"
                    label="修改时间" align="center">
                </el-table-column>

            </el-table>
        </div>

        <div style="float:right;margin-top:15px;">
            <el-pagination
                :current-page="cond.page"
                background
                layout="total,  prev, pager, next,sizes,jumper"
                :total="total"
                :page-size='cond.limit'
                :page-sizes="[15, 30, 50, 100]"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange">
            </el-pagination>
        </div>

        <el-dialog
            :title="editTitle"
            :visible.sync="dialogVisible"
            width="30%"
            :before-close="handleClose">
            
            <el-form :model="form">
                 <el-form-item  class="queryCondition" label="所属单据" label-width="120px">
                    <el-select v-model="form.bill_category_id" placeholder="请选择内容" style="width:200px;" clearable >
                        <el-option
                        v-for="item in options"
                        :key="item.bill_category_id"
                        :label="item.name"
                        :value="item.bill_category_id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item style="margin-top:20px;" label="排序" class="queryCondition"  label-width="120px">
                    <el-input style="width:48%;" maxlength="6" placeholder="请输入" v-model="form.order_no" clearable></el-input>
                </el-form-item>

                <el-form-item style="margin-top:20px;" label="单据类型名称" class="queryCondition"  label-width="120px">
                    <el-input style="width:48%;" maxlength="6" placeholder="请输入" v-model="form.name" clearable></el-input>
                </el-form-item>

            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="chooseType">确 定</el-button>
            </span>
        </el-dialog>


    </div>
</template>

<script>
import storage from "good-storage";
import Cookies from "js-cookie";
import {
BillTypeList,
getBillCategoryList,
addBillCategoryList,
deleteBillCategoryList,setOnoff,
editBillCategoryList,
importBillCategoryList,
exportBillCategoryList,} from "@/api/goods/safeguard";
export default{
    created(){
        this.getBillTypeList()
        this.getBillCategoryList()
        this.token = storage.session.get("token") || Cookies.get("token");  
        const env = process.env.NODE_ENV;
        const host = window.location.host;
         if (env === "development") {
            this.baseUrl = "https://api.pretest.etouch.vip";
            } else {
            if (host === "edianbao.dev.etouch.vip") {
                this.baseUrl = "http://api.dev.etouch.vip";
            }
            if (host === "edianbao.test.etouch.vip") {
                this.baseUrl = "https://api.test.etouch.vip";
            }
            if (host === "www.etouch.top") {
                this.baseUrl = "https://api.etouch.top";
            }
            if (host === "edianbao.pretest.etouch.vip") {
                this.baseUrl = "https://api.pretest.etouch.vip";
            }
            if (host === "edianbao.test2.etouch.vip") {
                this.baseUrl = "http://api.test2.etouch.vip";
            }
        }
    },
    data(){
        return{
            token:'',
            baseUrl:'',
            dialogVisible:false,
            total:0,
            cond:{
                page:1,
                limit:15,
            },
            form:{},
            options:[],
            tableData:[],
            rowClickList:[],
            editTitle:'',
            editId:'',
            loading:false,
        }
    },
    methods:{

        handleImportSuccess(res, file) {
            console.log(res,file)
            if (res.code === 1) {
                this.$message({
                    type: "success",
                    message: "导入成功",
                });
                this.getBillTypeList();
            if (res.data.data_err.length !== 0) {
                res.data.data_err.forEach((item) => {
                    this.$message({
                    type: "error",
                    message: res.msg,
                    });
                });
            }
            } else {
                this.$message({
                type: "error",
                message: res.msg,
                });
            }
        },
        
        //导出列表
      exportList(){
           this.$notify({
            title: '导出数据',
            message: '正在导出入库单列表',
            position: 'bottom-right'
          });
          exportBillCategoryList()
              .then((res) => {
              const blob = new Blob([res], { type: "application/vnd.ms-excel" });
              const time = new Date().getTime();
              const a = document.createElement("a");
              a.setAttribute("download", `单据类型列表.xls`);
              a.href = URL.createObjectURL(blob);
              a.click();
          })
          .catch(() => {
          });
      },

        
        //开关
        switchChange(row){
            setOnoff({
                bill_type_ids:row.bill_type_id,
                status:row.status,
            }).then(res=>{
                if(res.code==1){
                    this.rowClickList=[]
                    this.getBillTypeList()
                }
            })
        },  

        //编辑弹框
        editBox(row){
            this.editId=row.bill_type_id
            this.editTitle='编辑单据类型'
            this.dialogVisible=true
            this.form=JSON.parse(JSON.stringify(row))
        },

          //刷新表格
        reflush(){
            this.getBillTypeList()
            this.rowClickList=[]
            this.loading=true
            setTimeout(()=>{
                this.loading = false;
            },1000)
        },

        //表格双击
        handleDalclick(row){
            this.editId=row.bill_type_id
            this.editTitle='编辑单据类型'
            this.dialogVisible=true
            this.form=JSON.parse(JSON.stringify(row))
        },
        
        //状态
        setOnoff(status){
            if(this.rowClickList.length==0){
                this.$message({
                    type:'warning',
                    message:'请先选中需要操作的数据'
                })
                return;
            }
            setOnoff({
                bill_type_ids:this.rowClickList.toString(),
                status,
            }).then(res=>{
                if(res.code==1){
                    this.rowClickList=[]
                    this.getBillTypeList()
                }
            })
        },

        //数据列表 获取全选
        tableGetAll(selection){
          if(selection.length>0){
              selection.forEach((item,index)=>{
                this.rowClickList.push(item.bill_type_id)
              })
          }else{
            this.rowClickList=[]
          }
        },

        //多选框change事件
       handleSelectionChangeList(selection,row){
            let index=this.rowClickList.indexOf(row.bill_type_id)
            if(index==-1){
                this.rowClickList.push(row.bill_type_id)
            }else{
                this.rowClickList.splice(index,1)
            }
        },

          //点击选中复选框
        clickRow(row,column,event){
            // if(column.property=='name'||column.property=="status"){
            //     return;
            // }
            this.$refs.multipleTable.toggleRowSelection(row);
            let index=this.rowClickList.indexOf(row.bill_type_id)
            if(index==-1){
                this.rowClickList.push(row.bill_type_id)
            }else{
                this.rowClickList.splice(index,1)
            }
        },


        //删除
        deleteBillCategoryList(){

             if(this.rowClickList.length==0){
                this.$message({
                    type:'warning',
                    message:'请先选中需要删除的数据'
                })
                return;
            }

            this.$confirm('确定删除选中的数据?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteBillCategoryList({
                    bill_type_ids:this.rowClickList.toString()
                }).then(res=>{
                        if(res.code==1){
                           res.data.forEach(item=>{
                                setTimeout(()=>{
                                if(item.code==501){
                                    this.$message({
                                    message: item.msg,
                                    type: 'success'
                                    });
                                }else{
                                    this.$message({
                                    message: item.msg,
                                    type: 'error'
                                    });
                                }
                                },50)
                            })
                        this.rowClickList=[]
                        this.getBillTypeList()
                    }
                })
            })
        },

        // 是否添加或编辑
        chooseType(){
            if(this.editTitle=='编辑单据类型'){
                this.editBillCategoryList()
            }else{
                this.addBillCategoryList()
            }
        },

        //编辑
        editBillCategoryList(){
            editBillCategoryList({
                bill_type_id:this.editId,
                name:this.form.name,
                order_no:this.form.order_no,
            }).then(res=>{
                if(res.code==1){
                    this.dialogVisible=false,
                    this.getBillTypeList()
                    this.$message({
                        message: '编辑成功',
                        type: 'success'
                    });
                }
            })
        },

        //添加
        addBillCategoryList(){
            addBillCategoryList({
                bill_category_id:this.form.bill_category_id,
                name:this.form.name,
                order_no:this.form.order_no,
            }).then(res=>{
                if(res.code==1){
                    this.dialogVisible=false,
                    this.getBillTypeList()
                    this.$message({
                        message: '添加成功',
                        type: 'success'
                    });
                }
            })
        },

        //单类型列表
        getBillCategoryList(){
            getBillCategoryList().then(res=>{
                if(res.code==1){
                    this.options=res.data
                }
            })
        },

        //获取列表
        getBillTypeList(){
            BillTypeList({
                ...this.cond
            }).then(res=>{
                if(res.code==1){
                    this.total=res.data.total
                    this.tableData=res.data.list
                }
            }).catch((err) => {
                this.loading = false;
            });
        },

        handleClose(){
            this.dialogVisible=false
        },

        // 添加数据
        addData(){
            this.editTitle='添加单据类型'
            this.form={}
            this.dialogVisible=true
        },

        reset(){
            this.cond={
                page:1,
                limit:15,
            }
        },

        handleCurrentChange(val){
            this.cond.page=val
            this.getBillTypeList()
        },

        handleSizeChange(val){
            this.cond.limit=val
            this.getBillTypeList()
        },
    }
}
</script>

<style scoped lang='less'>
// 表头样式
/deep/ .el-table th{
  height: 32px !important;
  background-color: rgba(242,242,242,1) !important;
  color: #595959 !important;
  font-size: 12px;
}
/deep/ .el-form-item{
    margin-bottom:5px;
}
/deep/  .el-table{
    tr{
      height: 35px !important;
    }
    .el-table__row{
       height: 40px !important;
    }

    th{
      height: 35px !important;
      padding: 0 !important;
    }
    .el-table__header-wrapper{
       height: 35px !important;
    }
  }
 .mian_tab{
    height:calc(100% - 24px);
    background: #fff;
    padding: 12px 20px;
    min-height: 500px;
    .listTable{
      height:calc(100% - 1540px);
    }
    .headerBox_title{
      font-size: 16px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #595959;
    }
    .headerBox{
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      align-items: center;
    }
  }
 .mian_top{
    background: #fff;
    padding: 9px 20px;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .mian_top_right{
      padding-top:3px;
      .senior_screening_btn{
        width: 80px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #0075FF;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #0075FF;
        cursor: pointer;
      }
      .senior_screening_query{
        width: 68px;
        height: 32px;
        line-height: 32px;
        background: #0075FF;
        text-align: center;
        color: #fff;
        border-radius: 4px 4px 4px 4px;
        margin-left: 20px;
        margin-right: 8px;
        cursor: pointer;
        padding: 0;
      }
      .senior_screening_reset{
        width: 68px;
        height: 32px;
        line-height: 32px;
        background: rgba(255,255,255,0.1);
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #D8D8D8;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #595959;
        text-align: center;
        cursor: pointer;
        padding: 0;
      }
    }
  }
</style>