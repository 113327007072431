import { post } from '@/utils/request';

// 门店列表
const BillTypeList = (data) => {
    return post({
        url: "/store/goods.BillTypeConfig/list",
        data,
    })
}

//类型列表
const getBillCategoryList=data=>{
    return post({
        url: "/store/goods.BillTypeConfig/getBillCategoryList",
        data,
    })
}

//添加
const addBillCategoryList=data=>{
    return post({
        url: "/store/goods.BillTypeConfig/add",
        data,
    })
}

//删除
const deleteBillCategoryList=data=>{
    return post({
        url: "/store/goods.BillTypeConfig/delete",
        data,
    })
}

//禁用
const setOnoff=data=>{
    return post({
        url: "/store/goods.BillTypeConfig/setOnoff",
        data,
    })
}

//编辑
const editBillCategoryList=data=>{
    return post({
        url: "/store/goods.BillTypeConfig/edit",
        data,
    })
}


//导出
const exportBillCategoryList=data=>{
    return post({
        url: "/store/goods.BillTypeConfig/export",
        data,
        responseType:'blob',
    })
}

//导入
const importBillCategoryList=data=>{
    return post({
        url: "/store/goods.BillTypeConfig/import",
        data,
        responseType:'blob',
    })
}


export {
    importBillCategoryList,
    exportBillCategoryList,
    editBillCategoryList,
    setOnoff,
    deleteBillCategoryList,
    addBillCategoryList,
    getBillCategoryList,
    BillTypeList,
};